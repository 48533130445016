<div id="sliding-popup" class="sliding-popup-bottom" *ngIf="show">
  <div class="popup-content info">
    <div id="popup-text">
      <p class="removeMarginbottom">
        {{ "cookie.cookiegebruik" | translate }}
      </p>
    </div>
    <div id="popup-buttons">
      <button
        type="button"
        class="agree-button eu-cookie-compliance-secondary-button btn-primary"
        (click)="btnClick(true)"
      >
        {{ "cookie.akkoord" | translate }}
      </button>
      <button
        type="button"
        routerLink="/policy"
        class="decline-button eu-cookie-compliance-default-button btn-primary"
      >
        {{ "cookie.cookiegebruik2" | translate }}
      </button>
    </div>
  </div>
</div>
