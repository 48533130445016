<footer id="sticky-footer" class="py-5 text-white footerColor">
  <div class="container row mx-auto st">
    <!-- Young Potentials and Privacy Statement -->
    <div class="youngPotentialsContainer col-6 col-md">
      <h5>Young Potentials</h5>
      <ul class="list-unstyled text-small">
        <li>
          <p class="text-muted">{{ "footer.subtitel" | translate }}</p>
        </li>
        <li>
          <a class="text-muted" routerLink="/policy" (click)="onActivate()">{{
            "policy.titel" | translate
          }}</a>
        </li>
      </ul>
    </div>
    <!-- Company Add Vacancy -->
    <div class="companyAddJobContainer col-6 col-md">
      <h5>{{ "footer.titelbedr" | translate }}</h5>
      <ul class="list-unstyled text-small">
        <li>
          <a
            routerLink="/vacancies/create"
            (click)="onActivate()"
            routerLinkActive="active"
            class="text-muted"
            >{{ "footer.vaclink" | translate }}</a
          >
        </li>
      </ul>
    </div>
    <!-- Header Items -->
    <div class="headerItemsContainer col-6 col-md">
      <h5>{{ "footer.titelstud" | translate }}</h5>
      <ul class="list-unstyled text-small">
        <!-- Overview job offers -->
        <li>
          <a
            class="text-muted"
            routerLink="/vacancies"
            (click)="onActivate()"
            >{{ "footer.overzichtvac" | translate }}</a
          >
        </li>
        <!-- Internships -->
        <li>
          <a
            class="text-muted"
            [routerLink]="['/vacancies', 1]"
            role="button"
            (click)="setCheckbox('Stage')"
            (click)="onActivate()"
            >{{ "footer.stages" | translate }}</a
          >
        </li>
        <!-- Summer Jobs -->
        <li>
          <a
            class="text-muted"
            [routerLink]="['/vacancies', 3]"
            role="button"
            (click)="setCheckbox('Vakantiejob')"
            (click)="onActivate()"
            >{{ "footer.jobstudent" | translate }}</a
          >
        </li>
        <!-- Pursue studies -->
        <li>
          <a
            class="text-muted"
            href="https://www.vives.be/nl/bijscholen/overzicht"
            >{{ "footer.verderst" | translate }}</a
          >
        </li>
        <!-- Further Learning -->
        <li>
          <a class="text-muted" routerLink="/navorming">{{
            "footer.navorming" | translate
          }}</a>
        </li>
      </ul>
    </div>
    <!-- Contact -->
    <div class="contactContainer col-6 col-md">
      <h5>Contact</h5>
      <ul class="list-unstyled text-small">
        <li>
          <a class="text-muted"
            ><i class="material-icons icon">call</i> 056 26 41 60 Kortrijk</a
          >
        </li>
        <li>
          <a class="text-muted"
            ><i class="material-icons icon">call</i> 050 30 51 00 Brugge</a
          >
        </li>
        <li>
          <a class="text-muted" (click)="onActivate()" routerLink="/faq"
            ><i class="material-icons icon">email</i>youngpotentials@vives.be

            </a
          >
        </li>
        <li>
          <a class="text-muted" href="https://www.vives.be/"
            ><i class="material-icons icon">public</i> vives.be</a
          >
        </li>
      </ul>
    </div>
    <!-- Vives Icon -->
    <div class="col-12 col-md text-center">
      <img class="footerLogo" src="../../assets/images/logo_white.svg" alt="VIVES hogeschool" />
      <small class="d-block mb-3 text-muted">Copyright &copy; 2023-2024</small>
    </div>
  </div>
</footer>
