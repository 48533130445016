import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  invokeFilterComponent = new EventEmitter();
  subsVar: Subscription;

  constructor() {}

  /**
   * @description clicks the matching checkbox in the filtercomponent, from the selection in the homecomponent
   */
  setCheckbox(name: string) {
    this.invokeFilterComponent.emit(name);
  }
}
