import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { proxy } from 'package.json';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { RegisterFormData } from '../../models/registerFormData';
import { User } from '../../models/user';
import { UserService } from '../user/user.service';
import { Role } from '../../models/role';

import { Router } from '@angular/router';

const MINUTES_UNITL_AUTO_LOGOUT = 1440 * 7; // in mins * days
const CHECK_INTERVAL = 5000; // in ms
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  val: any;
  localStorageKey = 'userData';
  JWT_TOKEN = 'userData.token';
  apiUrl = proxy;
  loggedInUserFromStorage: BehaviorSubject<User>;
  loggedInUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router
  ) {
    this.loggedInUserFromStorage = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(this.localStorageKey))
    );
    this.loggedInUser = this.loggedInUserFromStorage.asObservable();
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }
  /**
   * checks the last action done
   */
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  /**
   * set time from last action
   * @param lastAction
   */
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  /**
   * resets the time from the last action
   */
  reset() {
    this.setLastAction(Date.now());
  }

  /**
   * add eventlisteners when the user is active, calls the reset() action when the user is active
   */
  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
    window.addEventListener('storage', () => this.storageEvt());
  }

  /**
   * checks if the user is active every interval
   */
  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  /**
   * checks if the user is active, if he is inactive for too long he will be logged out automatically
   */
  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      localStorage.clear();
      this.logout();
      this.router.navigate(['/home']);
    }
  }
  /**
   * stores the value from localStorage (STORE_KEY) in val
   */
  storageEvt() {
    this.val = localStorage.getItem(STORE_KEY);
  }

  /**
   * @description registers the user as a student or company
   * @param registerFormData data needed to register as an user (student or company)
   */
  register(registerFormData: RegisterFormData) {
    return this.http
      .post<any>(`${this.apiUrl}/user/register`, registerFormData)
      .pipe(
        tap((userData) => {
          if (userData && userData.token) {
            //Saving user data in local storage
            this.userService.storeUserData(userData);

            //Updating logged in user observable
            this.loggedInUserFromStorage.next(userData);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * @description Logs in the user
   * @param email The email from the user
   * @param password The password from the user
   */
  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}/user/authenticate`, { email, password })
      .pipe(
        tap((userData) => {
          if (userData && userData.token) {
            //Saving user data in local storage
            this.userService.storeUserData(userData);

            //Updating logged in user observable
            this.loggedInUserFromStorage.next(userData);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * @description Logs out the user by deleting local storage
   */
  logout() {
    localStorage.removeItem('userData');
    this.loggedInUserFromStorage.next(null);
  }

  /**
   * @description Returns true if there is a JWT token otherwise false
   */
  isLoggedIn() {
    return !!this.getJwtToken();
  }

  /**
   * @description Returns the JWT token from localstorage
   */
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  /**
   * @description Saves user data such as the JWT token, email adress etc... in local storage
   * @param userData Data from the user
   */
  // storeUserData(userData) {
  //   localStorage.setItem('userData', JSON.stringify(userData));
  // }

  /**
   * @description Returns the diplayname
   */
  getDisplayName() {
    let user = this.userService.getUser();
    if (user != null) {
      switch (user.role) {
        case Role.User: {
          return user.firstName + ' ' + user.name;
        }
        case Role.Company: {
          return user.companyName;
        }
        case Role.Admin: {
          return 'Administrator';
        }
      }
    }
  }
  getUserIdFromStorage() {
    let user = JSON.parse(localStorage.getItem(this.localStorageKey));
    return user.id;
  }
}
